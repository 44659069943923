import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | 11th & Bay Southern Table",
  pageDescription:
    "Learn how a southern casual dining restaurant leveraged SpotOn Reserve to increase table turns and break revenue barriers.",
  title: "A southern restaurant sees profits go north",
  subTitle: `How 11th and Bay maximized their table turns to boost revenue.`,
  businessName: "11th & Bay Southern Table",
  industry: "Restaurants & hospitality, fine dining",
  location: "Columbus, Georgia",
  products: [spotonProducts.RESERVE],
  description:
    "Owner Mike Harrell brings southern cuisine and hospitality to Columbus, Georgia with his restaurant, 11th and Bay. By implementing SpotOn Reserve, he can turn more tables and generate more sales, along with higher server incomes.",
  quoteData: {
    imgName: "southern-table.png",
    quote: ` “Our average Friday and Saturday sales used to be $9k - $10k. With SpotOn Reserve, we take in $12k, $13k, $14k and run more smoothly because there’s no longer that big crash in the kitchen.” `,
    personName: "Mike Harrell",
    personTitle: "Owner, 11th and Bay Southern Table",
  },
  goal: `After multiple reservations systems had let him down, Mike Harrell was looking for a platform where his guests could easily book reservations online. He also was hoping to consistently fill the third table turn to break his revenue barrier.`,
  solution: `With data from SpotOn Reserve, Harrell could determine the most efficient table turn times and control the pace of the evening. Along with fairly distributing tables to servers, the reservation solution also optimized seating to increase profits by 28%.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `33%`, description: `increase in table turns` },
      {
        title: `$4,000`,
        description: `increase in weekend sales`,
      },
      { title: `$20,000`, description: `increase in servers' annual income` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Fine dining reimagined",
    imageName: "imaginate-restaurant.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-21",
    href: "/case-studies/imaginate-restaurant",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
